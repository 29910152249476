import React from 'react';
import { Box, Button, Card, CardActions, CardOverflow, Checkbox, Divider, FormControl, FormLabel, IconButton, Input, LinearProgress, Modal, ModalClose, ModalDialog, Sheet, Stack, Textarea, Typography } from '@mui/joy';
import Grid from '@mui/joy/Grid';
import styled from 'styled-components';
import DiagnosticsView, { ChainNode } from './Diagnostics';
import { ChatInputPanel, darkTheme, MessagePane, ThemeProviderWrapper, useFablecast } from '@fablecast/components';
import FingerprintIcon from '@mui/icons-material/Fingerprint';
import VpnKeyIcon from '@mui/icons-material/VpnKey';
import AddCircleOutline from '@mui/icons-material/AddCircleOutline';
import Delete from '@mui/icons-material/Delete';
import Table from '@mui/joy/Table';
import { ai, DiagnosticPayload, ErrorPayload, FablecastGame } from 'fablecast-client';

export function FablecastLogin() {
  localStorage.setItem("fablecastIdentityToken", "")
  const queryString = window.location.hash;
  const urlParams = new URLSearchParams(queryString);
  const token = urlParams.get('access_token') || urlParams.get("#access_token")
  if (token === null) {
    const errorDesc = urlParams.get('error_description');
    return (
      <Sheet
        sx={{
          display: 'flex',
          flexDirection: 'column',
          backgroundColor: 'background.level1',
          p: 4
        }}
      >
        <Card variant='outlined'>{errorDesc}</Card>
      </Sheet>
    );
  } else {
    localStorage.setItem("fablecastIdentityToken", token)
    window.close();
    return (<></>)
  }

}

const WindowContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  flex: 1;
`;

const ChatContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  maxHeight: 100%;
  overflow-y: auto;
  min-height: calc(100vh - 191px);
  margin: 0 auto;
  padding: 0 auto;
  background-color: ${darkTheme.colors.background};
  color: ${darkTheme.colors.text};
  flex: 1;
  @media (min-width: 900px) {
    padding: ${darkTheme.spacing.lg};
    min-height: 0;
    width: 900px;
    backdrop-filter: blur(10px);
    border-radius: 12px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
`;


const MessageContainer = styled.div`
  flex: 1;
  margin-bottom: 50px; /* Space for input panel */
`;

export function MainWindow({
  fablecastApiEndpoint,
  setFablecastApiEndpoint,
  fablecastAccountEndpoint,
  setFablecastAccountEndpoint,
  fablecastForgeApiEndpoint,
  setFablecastForgeApiEndpoint,
  fablecastAccessToken,
  setFablecastAccessToken,
  setNarrativeChain,
  setError,
  fablecastGame,
  setFablecastGame
}: {
  fablecastApiEndpoint: string,
  setFablecastApiEndpoint: React.Dispatch<React.SetStateAction<string>>
  fablecastAccountEndpoint: string,
  setFablecastAccountEndpoint: React.Dispatch<React.SetStateAction<string>>
  fablecastForgeApiEndpoint: string,
  setFablecastForgeApiEndpoint: React.Dispatch<React.SetStateAction<string>>
  fablecastAccessToken: string,
  setFablecastAccessToken: React.Dispatch<React.SetStateAction<string>>,
  setNarrativeChain: React.Dispatch<React.SetStateAction<ChainNode>>,
  setError: React.Dispatch<React.SetStateAction<ErrorPayload | undefined>>,
  fablecastGame?: FablecastGame,
  setFablecastGame: React.Dispatch<React.SetStateAction<FablecastGame | undefined>>
}) {
  const fablecast = useFablecast();
  const [isConnecting, setIsConnecting] = React.useState<boolean>(false);
  const [debug, setDebug] = React.useState<boolean>(localStorage.getItem('debugMode') === "true" || localStorage.getItem('debugMode') === null);
  const [placementPayload, setPlacementPayload] = React.useState<string>(localStorage.getItem('placementPayload') || JSON.stringify({
    placementId: "fake-placement-id",
    port: 8080,
    playerSessionId: "fake-session-id",
    dns: "localhost",
    startTime: new Date().toISOString,
    status: ai.fablecast.PlacementStatus.FULFILLED.name
  }, undefined, 2));
  const [pathOverride, setPathOverride] = React.useState<string>(localStorage.getItem('pathOverride') || "/storyteller/bricksbribes/DEV");
  const [promptOverrides, setPromptOverrides] = React.useState<Record<string, number>>(JSON.parse(localStorage.getItem('promptOverrides') || "{}"));
  const [promptOverrideName, setPromptOverrideName] = React.useState<string>("");
  const [promptOverrideVersion, setPromptOverrideVersion] = React.useState<string>("");

  const handleLogin = async () => {
    const cognitoUri = 'mindmage.auth.eu-west-2.amazoncognito.com'
    const cognitoClientId = '2osv7j87hvndmhtnjdp9h0aqda';
    const redirectUri = `${window.location.origin}/oauth2/idpresponse`;
    const idp = 'MindMageActiveDirectory'


    const cognitoLoginUrl = `https://${cognitoUri}/login?client_id=${cognitoClientId}&response_type=token&identity_provider=${idp}&scope=openid+email&redirect_uri=${encodeURIComponent(redirectUri)}`;
    const loginWindow = window.open(cognitoLoginUrl, '_blank', 'width=600,height=600');

    if (loginWindow) {
      const checkWindowClosed = setInterval(() => {
        if (loginWindow.closed) {
          clearInterval(checkWindowClosed);
          setFablecastAccessToken(localStorage.getItem('fablecastIdentityToken') || '');
        }
      }, 500);
    }
  };

  return fablecastGame === undefined ? (
    <div style={{width: "100%", height: "100%", padding: "64px", alignContent: "center", alignItems: "center"}}>
        <Card sx={{maxWidth: "800px", marginLeft: "auto", marginRight: "auto"}}>
          <Box sx={{ mb: 1 }}>
            <Typography level="title-md">Start Fablecast Session</Typography>
          </Box>
          <Divider />
          <Stack
            direction="row"
            spacing={3}
            sx={{ display: { xs: 'none', md: 'flex' }, my: 1 }}
          >
            <Stack spacing={2} sx={{ flexGrow: 1 }}>
              <Stack spacing={1}>
                <FormLabel>API Endpoints</FormLabel>
                <FormControl
                  sx={{ display: { sm: 'flex-column', md: 'flex-row' }, gap: 2 }}
                >
                  <Input size="sm" placeholder="API endpoint" value={fablecastApiEndpoint} onChange={(e) => {setFablecastApiEndpoint(e.target.value)}}/>
                  <Input size="sm" placeholder="Accounts endpoint" value={fablecastAccountEndpoint} onChange={(e) => {setFablecastAccountEndpoint(e.target.value)}}/>
                  <Input size="sm" placeholder="Forge endpoint" value={fablecastForgeApiEndpoint} onChange={(e) => {setFablecastForgeApiEndpoint(e.target.value)}}/>
                </FormControl>
              </Stack>
              <Stack spacing={1}>
                <FormLabel>Placement</FormLabel>
                <Textarea size="sm" minRows="4" placeholder="Placement Payload" value={placementPayload} onChange={(e) => {setPlacementPayload(e.target.value)}}/>
              </Stack>
              <Stack spacing={1}>
                <FormLabel>Path Override</FormLabel>
                <FormControl
                  sx={{ display: { sm: 'flex-column', md: 'flex-row' }, gap: 2 }}
                >
                  <Input size="sm" placeholder="Path Override" value={pathOverride} onChange={(e) => {setPathOverride(e.target.value)}}/>
                </FormControl>
              </Stack>
              <Stack spacing={1}>
                <FormLabel>Access Token</FormLabel>
                <FormControl
                  sx={{ display: { sm: 'flex-column', md: 'flex-row' }, gap: 2 }}
                >
                  <Input size="sm" placeholder="Access Token" value={fablecastAccessToken} onChange={(e) => {setFablecastAccessToken(e.target.value)}} 
                        startDecorator={<FingerprintIcon />}
                        endDecorator={<IconButton onClick={handleLogin}><VpnKeyIcon /></IconButton>}/>
                </FormControl>
              </Stack>
              <Stack spacing={1}>
                <FormControl
                  sx={{ display: { sm: 'flex-column', md: 'flex-row' }, gap: 2 }}
                >
                  <Checkbox label="Debug Mode" checked={debug} onChange={(e) => setDebug(e.target.checked)}/>
                </FormControl>
              </Stack>
              { debug && (<Stack spacing={1}>
                <FormLabel>Prompt Overrides</FormLabel>
                <Table>
                <thead>
                  <tr>
                    <th>Prompt Name</th>
                    <th>Version</th>
                    <th style={{width: "64px"}}></th>
                  </tr>
                </thead>
                <tbody>
                  {Object.entries(promptOverrides).map(e => (
                    <tr key={e[0]}>
                      <td>
                        {e[0]}
                      </td>
                      <td>
                        {e[1]}
                      </td>
                      <td>
                        <Button onClick={() => {setPromptOverrides(po => {
                          const newOut = {...po}
                          delete newOut[e[0]]
                          return newOut
                        })}}>
                          <Delete/>
                        </Button>
                      </td>
                    </tr>
                  ))}
                  
                  <tr>
                    <td>
                      <Input size="sm" placeholder="Prompt Name" value={promptOverrideName} onChange={(e) => {setPromptOverrideName(e.target.value)}}/>
                    </td>
                    <td>
                      <Input size="sm" placeholder="PromptVersion" value={promptOverrideVersion} onChange={(e) => {setPromptOverrideVersion(e.target.value)}}/>
                    </td>
                    <td>
                      <Button disabled={promptOverrideVersion === "" || promptOverrideName === "" || promptOverrideName in promptOverrides || isNaN(+promptOverrideVersion)} onClick={() => {
                        setPromptOverrides(po => {
                          const newOut = {...po}
                          newOut[promptOverrideName] = +promptOverrideVersion
                          return newOut
                        })
                        setPromptOverrideName("")
                        setPromptOverrideVersion("")
                      }}>
                        <AddCircleOutline/>
                      </Button>
                    </td>
                  </tr>
                </tbody>
                </Table>
              </Stack>) }
            </Stack>
          </Stack>
          <CardOverflow sx={{ borderTop: '1px solid', borderColor: 'divider' }}>
            {isConnecting && <LinearProgress />}
            <CardActions sx={{ alignSelf: 'flex-end', pt: 2 }}>
              <Button size="sm" variant="solid" onClick={() => {
                  localStorage.setItem('pathOverride', pathOverride);
                  localStorage.setItem('debugMode', debug ? "true" : "false");
                  localStorage.setItem('placementPayload', placementPayload);
                  localStorage.setItem('promptOverrides', JSON.stringify(promptOverrides));
                  setIsConnecting(true);
                  setNarrativeChain(new ChainNode())
                  const placementModel = JSON.parse(placementPayload) as {
                    placementId: string,
                    port: number | undefined,
                    playerSessionId: string | undefined,
                    dns: string | undefined,
                    startTime: string | undefined,
                    status: string,
                  }
                  const placementFC: ai.fablecast.Placement = new ai.fablecast.Placement(
                    placementModel.placementId,
                    placementModel.port,
                    placementModel.playerSessionId,
                    placementModel.dns,
                    placementModel.startTime,
                    ai.fablecast.PlacementStatus.valueOf(placementModel.status)
                  )

                  fablecast.controller.connect(
                    placementFC,
                    pathOverride === "" ? undefined : pathOverride,
                    debug,
                    promptOverrides,
                    (game: FablecastGame, _: string | undefined) => {
                      setIsConnecting(false)
                      setFablecastGame(game)
                      setNarrativeChain(new ChainNode());
                    },
                    (_) => {
                      setFablecastGame(undefined)
                    },
                    (error: ErrorPayload) => {
                      setError(error)
                    },
                    (diagnostic: DiagnosticPayload) => {
                      setNarrativeChain(c => c.attachEvent(diagnostic))
                    });
              }}>
                Connect
              </Button>
            </CardActions>
          </CardOverflow>
        </Card>
    </div>
  ) : (<ThemeProviderWrapper>
    <WindowContainer>
      <ChatContainer>
        <MessageContainer>
          <MessagePane isFollowing={false} setIsFollowing={() => {}}/>
        </MessageContainer>
      </ChatContainer>
      <div style={{flexShrink: 1}}>
        <ChatInputPanel fixed={false} game={fablecastGame}/>
      </div>
    </WindowContainer>
  </ThemeProviderWrapper>);
}

export default function Fablecast({
  fablecastApiEndpoint,
  setFablecastApiEndpoint,
  fablecastAccountEndpoint,
  setFablecastAccountEndpoint,
  fablecastForgeApiEndpoint,
  setFablecastForgeApiEndpoint,
  fablecastAccessToken,
  setFablecastAccessToken
}: {
  fablecastApiEndpoint: string,
  setFablecastApiEndpoint: React.Dispatch<React.SetStateAction<string>>
  fablecastAccountEndpoint: string,
  setFablecastAccountEndpoint: React.Dispatch<React.SetStateAction<string>>
  fablecastForgeApiEndpoint: string,
  setFablecastForgeApiEndpoint: React.Dispatch<React.SetStateAction<string>>
  fablecastAccessToken: string,
  setFablecastAccessToken: React.Dispatch<React.SetStateAction<string>>
}) {
  const [narrativeChain, setNarrativeChain] = React.useState<ChainNode>(new ChainNode());
  
  const [currentDiagnostic, setCurrentDiagnostic] = React.useState<DiagnosticPayload>();
  const [error, setError] = React.useState<ErrorPayload>();
  const [fablecastGame, setFablecastGame] = React.useState<FablecastGame>();

  React.useEffect(() => {
    setNarrativeChain(new ChainNode());
    setCurrentDiagnostic(undefined);
  }, [fablecastGame]);

  return (<>
    {narrativeChain.children.length === 0 ? <MainWindow
      fablecastApiEndpoint={fablecastApiEndpoint}
      setFablecastApiEndpoint={setFablecastApiEndpoint}
      fablecastAccountEndpoint={fablecastAccountEndpoint}
      setFablecastAccountEndpoint={setFablecastAccountEndpoint}
      fablecastForgeApiEndpoint={fablecastForgeApiEndpoint}
      setFablecastForgeApiEndpoint={setFablecastForgeApiEndpoint}
      fablecastAccessToken={fablecastAccessToken}
      setFablecastAccessToken={setFablecastAccessToken}
      setNarrativeChain={setNarrativeChain}
      setError={setError}
      fablecastGame={fablecastGame}
      setFablecastGame={setFablecastGame}
      /> : (
        <Grid container spacing={2} sx={{ display: 'flex', height: '100%', p: 0, m: 0 }}>
          <Grid sx={{ flex: 2, height: '100%', overflow: 'hidden', p: 0, m: 0 }}>
            <MainWindow
              fablecastApiEndpoint={fablecastApiEndpoint}
              setFablecastApiEndpoint={setFablecastApiEndpoint}
              fablecastAccountEndpoint={fablecastAccountEndpoint}
              setFablecastAccountEndpoint={setFablecastAccountEndpoint}
              fablecastForgeApiEndpoint={fablecastForgeApiEndpoint}
              setFablecastForgeApiEndpoint={setFablecastForgeApiEndpoint}
              fablecastAccessToken={fablecastAccessToken}
              setFablecastAccessToken={setFablecastAccessToken}
              setNarrativeChain={setNarrativeChain}
              setError={setError}
              fablecastGame={fablecastGame}
              setFablecastGame={setFablecastGame}
              />
          </Grid>
          <Grid sx={{ flex: 1, p: 4, m: 0,  height: '100%'}}>
            <DiagnosticsView diagnostic={currentDiagnostic} narrativeChain={narrativeChain} setCurrentDiagnostic={setCurrentDiagnostic} />
          </Grid>
        </Grid>
      )}

    <Modal open={error !== undefined} onClose={() => { setError(undefined) }}>
      <ModalDialog
        color="danger"
        layout="center"
        variant="solid"
      >
        <ModalClose />
        <Typography level={"h4"}>{error?.message}</Typography>
        {error?.stackTrace !== undefined && (
          <Sheet sx={{ p: 2, borderRadius: 8 }}>
            {error?.stackTrace?.map(t => (
              <pre style={{ margin: 0, padding: 0 }}>{t}</pre>
            ))}
          </Sheet>
        )}
      </ModalDialog>
    </Modal>
  </>
  );
}
