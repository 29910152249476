import React from 'react';
import { IconButton, Box, Chip, Input, List, Typography, Stack, Sheet, LinearProgress, Modal, Card, Button, ButtonGroup, Alert } from '@mui/joy';
import AddIcon from '@mui/icons-material/Add';
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import NarrativeListingView, { NarrativeListing } from './NarrativeListing';
import UploadIcon from '@mui/icons-material/Upload';
import { NarrativeEndpoints } from './Narratives';

type NarrativeListingsProps = {
  selectedListing: NarrativeListing | undefined
  setSelectedListing: React.Dispatch<React.SetStateAction<NarrativeListing | undefined>>
  narrativeEndpoints: NarrativeEndpoints
  userJwt: string
  storeEnv: string
  setStoreEnv: React.Dispatch<React.SetStateAction<string>>
};

export default function NarrativeListings({selectedListing, setSelectedListing, narrativeEndpoints, userJwt, storeEnv, setStoreEnv}: NarrativeListingsProps) {
  const [listings, setListings] = React.useState<NarrativeListing[]>([]);
  const [searching, setSearching] = React.useState<boolean>(true);
  const [searchString, setSearchString] = React.useState<string>("");
  const [createNewModalOpen, setCreateNewModalOpen] = React.useState(false);
  const [newID, setNewID] = React.useState("");
  const [newVersion, setNewVersion] = React.useState("");
  const fileInputRef = React.useRef<HTMLInputElement>(null);
  const [isUploading, setIsUploading] = React.useState(false);
  const [uploadStatus, setUploadStatus] = React.useState<{
    show: boolean;
    type: 'success' | 'error';
    message: string;
  }>({ show: false, type: 'success', message: '' });

  const showStatus = (type: 'success' | 'error', message: string) => {
    setUploadStatus({ show: true, type, message });
    setTimeout(() => {
      setUploadStatus(prev => ({ ...prev, show: false }));
    }, 5000);
  };

 const handleFileUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (!file) return;
    
    if (!newID || !newVersion) {
      showStatus('error', 'Please provide both Narrative ID and Version');
      return;
    }

    setIsUploading(true);
    try {
      const response = await fetch(`${narrativeEndpoints.narrativeUploadEndpoint}?env=${storeEnv}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${userJwt}`
        },
        mode: 'cors',
        body: JSON.stringify({
          id: newID,
          version: newVersion
        })
      });

      if (!response.ok) {
        throw new Error('Failed to get upload URL');
      }

      const { uploadUrl } = await response.json();

      const uploadResponse = await fetch(uploadUrl, {
        method: 'PUT',
        body: file,
        mode: 'cors',
        headers: {
          'Content-Type': 'application/gzip',
        }
      });

      if (!uploadResponse.ok) {
        throw new Error('Upload failed');
      }

      showStatus('success', 'New narrative uploaded successfully');
      // Clear the form
      setNewID('');
      setNewVersion('');
      if (fileInputRef.current) {
        fileInputRef.current.value = '';
      }
      setCreateNewModalOpen(false);
      // Trigger a refresh of the listings
      setSearchString(prev => prev + ' '); // This will trigger the useEffect
    } catch (error) {
      console.error('Upload error:', error);
      showStatus('error', `Upload failed: ${error instanceof Error ? error.message : 'Unknown error'}`);
    } finally {
      setIsUploading(false);
    }
  };


  React.useEffect(() => {
    setSearching(true)
    fetch(`${narrativeEndpoints.narrativeSearchEndpoint}?env=${storeEnv}&search=${searchString}`, {
      method: "GET",
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${userJwt}`
      },
      mode: 'cors'
    })
    .then(res => res.json())
    .then(res => setListings(res.listings))
    .then(() => setSearching(false))
  }, [searchString, setSearching, setListings, selectedListing, userJwt, narrativeEndpoints, storeEnv])

  return (
    <Sheet
      sx={{
        borderRight: '1px solid',
        borderColor: 'divider',
        height: { sm: 'calc(100dvh - var(--Header-height))', md: '100dvh' },
        overflowY: 'auto',
      }}
    >
      <Stack
        direction="row"
        spacing={1}
        sx={{ alignItems: 'center', justifyContent: 'space-between', p: 2, pb: 1.5 }}
      >
        <Typography
          component="h1"
          endDecorator={
            <Chip
              variant="soft"
              color="primary"
              size="md"
              slotProps={{ root: { component: 'span' } }}
            >
              {listings.length}
            </Chip>
          }
          sx={{ fontSize: { xs: 'md', md: 'lg' }, fontWeight: 'lg', mr: 'auto' }}
        >
          Narratives
        </Typography>
        <ButtonGroup variant="soft" size="sm" aria-label="environment toggle">
          <Button 
            variant={storeEnv === 'test' ? 'solid' : 'soft'}
            onClick={() => setStoreEnv('test')}
          >
            Test
          </Button>
          <Button
            variant={storeEnv === 'prod' ? 'solid' : 'soft'}
            onClick={() => setStoreEnv('prod')}
          >
            Prod
          </Button>
        </ButtonGroup>
        <IconButton
          variant="plain"
          aria-label="edit"
          color="neutral"
          size="sm"
          sx={{ display: { xs: 'none', sm: 'unset' } }}
          onClick={() => {
            setNewID("")
            setNewVersion("")
            setCreateNewModalOpen(true)
          }}
        >
          <AddIcon />
        </IconButton>
        <Modal 
        open={createNewModalOpen} 
        onClose={() => {
          if (!isUploading) {
            setCreateNewModalOpen(false);
            setNewID('');
            setNewVersion('');
            setUploadStatus({ show: false, type: 'success', message: '' });
          }
        }}
      >
        <Box sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 400,
          p: 4
        }}>
          <Card variant="outlined">
            <Typography level="title-lg" sx={{ mb: 2 }}>
              Create New Narrative
            </Typography>
            {uploadStatus.show && (
              <Alert
                variant="soft"
                color={uploadStatus.type === 'success' ? 'success' : 'danger'}
                sx={{ mb: 2 }}
                endDecorator={
                  <Button 
                    variant="soft" 
                    color={uploadStatus.type === 'success' ? 'success' : 'danger'}
                    size="sm"
                    onClick={() => setUploadStatus(prev => ({ ...prev, show: false }))}
                  >
                    Dismiss
                  </Button>
                }
              >
                {uploadStatus.message}
              </Alert>
            )}
            <Stack spacing={2}>
              <Input
                size="sm"
                placeholder="Narrative ID"
                value={newID}
                onChange={(e) => setNewID(e.target.value)}
                disabled={isUploading}
              />
              <Input
                size="sm"
                placeholder="Narrative Version"
                value={newVersion}
                onChange={(e) => setNewVersion(e.target.value)}
                disabled={isUploading}
              />
              <input
                type="file"
                ref={fileInputRef}
                style={{ display: 'none' }}
                onChange={handleFileUpload}
                accept=".tar.gz"
              />
              <Button
                size="lg"
                variant="soft"
                color="primary"
                startDecorator={<UploadIcon />}
                onClick={() => fileInputRef.current?.click()}
                loading={isUploading}
                disabled={isUploading || !newID || !newVersion}
                sx={{ width: '100%' }}
              >
                {isUploading ? 'Uploading...' : 'Upload'}
              </Button>
              <Button
                size="sm"
                variant="plain"
                color="neutral"
                onClick={() => setCreateNewModalOpen(false)}
                disabled={isUploading}
              >
                Cancel
              </Button>
            </Stack>
          </Card>
        </Box>
      </Modal>
      </Stack>
      <Box sx={{ px: 2, pb: 1.5 }}>
        <Input
          size="sm"
          startDecorator={<SearchRoundedIcon />}
          placeholder="Search"
          aria-label="Search"
          value={searchString}
          onChange={(e) => setSearchString(e.target.value)}
        />
      </Box>
      <List
        sx={{
          py: 0,
          '--ListItem-paddingY': '0.75rem',
          '--ListItem-paddingX': '1rem',
        }}
      >
        {searching ? <LinearProgress /> : <div style={{height: '6px'}} />}
         {listings.map((listing) => (
          <NarrativeListingView
            listing={listing}
            selectedListing={selectedListing}
            setSelectedListing={setSelectedListing}
          />
        ))}
      </List>
    </Sheet>
  );
}