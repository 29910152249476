import React from 'react';
import { NarrativeListing } from './NarrativeListing';
import Box from '@mui/joy/Box';
import Chip from '@mui/joy/Chip';
import Sheet from '@mui/joy/Sheet';
import Typography from '@mui/joy/Typography';
import Button from '@mui/joy/Button';
import Divider from '@mui/joy/Divider';
import DeleteIcon from '@mui/icons-material/Delete';
import { NarrativeEndpoints } from './Narratives';
import PublishIcon from '@mui/icons-material/Publish';
import { Alert, ButtonGroup, Card, Modal } from '@mui/joy';
import UploadIcon from '@mui/icons-material/Upload';
import DownloadIcon from '@mui/icons-material/Download';

type NarrativeEditorProps = {
  selectedListing: NarrativeListing
  setSelectedListing: React.Dispatch<React.SetStateAction<NarrativeListing | undefined>>
  narrativeEndpoints: NarrativeEndpoints
  userJwt: string
  storeEnv: string
};

export default function NarrativeEditor({selectedListing, setSelectedListing, narrativeEndpoints, userJwt, storeEnv}: NarrativeEditorProps) {
  const [deleteModalOpen, setDeleteModalOpen] = React.useState(false);
  const [isUploading, setIsUploading] = React.useState(false);
  const fileInputRef = React.useRef<HTMLInputElement>(null);
  const [uploadStatus, setUploadStatus] = React.useState<{
    show: boolean;
    type: 'success' | 'error';
    message: string;
  }>({ show: false, type: 'success', message: '' });

  function deleteNarrativeVersion() {
    fetch(`${narrativeEndpoints.narrativeDeleteEndpoint}?env=${storeEnv}`, {
      method: "POST",
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${userJwt}`
      },
      mode: 'cors',
      body: JSON.stringify({
        "id": selectedListing?.id,
        "version": selectedListing?.version
      })
    })
    .then(() => setSelectedListing(undefined))
    .then(() => setDeleteModalOpen(false))
  }

  function publish() {
    fetch(`${narrativeEndpoints.narrativePublishEndpoint}?env=${storeEnv}`, {
      method: "POST",
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${userJwt}`
      },
      mode: 'cors',
      body: JSON.stringify({
        "id": selectedListing?.id,
        "version": selectedListing?.version
      })
    })
    .then(() => setSelectedListing({...selectedListing!, live: true}))
  }

  const showStatus = (type: 'success' | 'error', message: string) => {
    setUploadStatus({ show: true, type, message });
    // Hide the status after 5 seconds
    setTimeout(() => {
      setUploadStatus(prev => ({ ...prev, show: false }));
    }, 5000);
  };

  const handleFileUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (!file) return;
  
    try {
      const response = await fetch(`${narrativeEndpoints.narrativeUploadEndpoint}?env=${storeEnv}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${userJwt}`
        },
        mode: 'cors',
        body: JSON.stringify({
          id: selectedListing.id,
          version: selectedListing.version
        })
      });
  
      if (!response.ok) {
        throw new Error('Failed to get upload URL');
      }
  
      const { uploadUrl } = await response.json();
  
      const uploadResponse = await fetch(uploadUrl, {
        method: 'PUT',
        body: file,
        mode: 'cors',
        headers: {
          'Content-Type': 'application/gzip',
        }
      });
  
      if (!uploadResponse.ok) {
        throw new Error('Upload failed');
      }
  
      showStatus('success', 'File uploaded successfully');

      console.log('Upload successful');
      if (fileInputRef.current) {
        fileInputRef.current.value = '';
      }
    } catch (error) {
      console.error('Upload error:', error);
      showStatus('error', `Upload failed: ${error instanceof Error ? error.message : 'Unknown error'}`);
    } finally {
      setIsUploading(false);
    }
  };
  
  const handleDownload = async () => {
    try {
      const response = await fetch(
        `${narrativeEndpoints.narrativeDownloadEndpoint}?env=${storeEnv}&id=${selectedListing.id}&version=${selectedListing.version}`, 
        {
          headers: {
            'Authorization': `Bearer ${userJwt}`
          }
        }
      );
  
      if (!response.ok) {
        throw new Error('Failed to get download URL');
      }
  
      const { downloadUrl } = await response.json();
  
      const link = document.createElement('a');
      link.href = downloadUrl;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
  
    } catch (error) {
      console.error('Download error:', error);
    }
  };

  return (
    <Sheet
      variant="outlined"
      sx={{ minHeight: 500, borderRadius: 'sm', p: 2, m: 3 }}
    >
      <Modal open={deleteModalOpen} onClose={() => setDeleteModalOpen(false)}>
        <Box sx= {{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 400,
          p: 4}}>
          <Card variant="outlined">
            <Typography>Are you sure you want to delete this Narrative Version?</Typography>
            <ButtonGroup sx={{width: '100%'}} buttonFlex={1}>
              <Button onClick={() => setDeleteModalOpen(false)}>Cancel</Button>
              <Button color='primary' variant='solid' onClick={() => deleteNarrativeVersion()}>Delete</Button>
            </ButtonGroup>
          </Card>
        </Box>
      </Modal>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          flexWrap: 'wrap',
          gap: 2,
        }}
      >
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Box sx={{ ml: 2 }}>
            <Typography
              level="title-lg"
              textColor="text.primary"
              endDecorator={ selectedListing.live && (
                <Chip component="span" size="sm" variant="outlined" color="warning">
                  Live
                </Chip>)
              }
            >
              {selectedListing.id}
            </Typography>
            <Typography level="body-sm" textColor="text.tertiary">
              {selectedListing.version}
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{ display: 'flex', height: '32px', flexDirection: 'row', gap: 1.5 }}
        >
          <Button
            size="sm"
            variant="plain"
            color="neutral"
            startDecorator={<DeleteIcon />}
            onClick={() => setDeleteModalOpen(true)}
          >
            Delete
          </Button>
          <Button
            size="sm"
            variant="plain"
            color="neutral"
            startDecorator={<PublishIcon />}
            disabled={selectedListing.live}
            onClick={() => publish()}
          >
            Publish
          </Button>
        </Box>
      </Box>
      <Divider sx={{ mt: 2 }} />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: 2,
          mt: 4,
        }}
      >
        {uploadStatus.show && (
          <Alert
            variant="soft"
            color={uploadStatus.type === 'success' ? 'success' : 'danger'}
            sx={{ width: '100%', maxWidth: 500 }}
            endDecorator={
              <Button 
                variant="soft" 
                color={uploadStatus.type === 'success' ? 'success' : 'danger'}
                size="sm"
                onClick={() => setUploadStatus(prev => ({ ...prev, show: false }))}
              >
                Dismiss
              </Button>
            }
          >
            {uploadStatus.message}
          </Alert>
        )}
        
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            gap: 4,
          }}
        >
          <input
            type="file"
            ref={fileInputRef}
            style={{ display: 'none' }}
            onChange={handleFileUpload}
            accept=".tar.gz"
          />
          <Button
            size="lg"
            variant="soft"
            color="primary"
            startDecorator={<UploadIcon />}
            onClick={() => fileInputRef.current?.click()}
            loading={isUploading}
            disabled={isUploading}
            sx={{ width: 200 }}
          >
            {isUploading ? 'Uploading...' : 'Upload'}
          </Button>
          <Button
            size="lg"
            variant="soft"
            color="primary"
            startDecorator={<DownloadIcon />}
            onClick={handleDownload}
            sx={{ width: 200 }}
          >
            Download
          </Button>
        </Box>
      </Box>
    </Sheet>
  );
}